
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent
} from '@ionic/vue'
import { defineComponent } from '@vue/runtime-core'
import MainLangSelection from '../components/MainLangSelection.vue'
import TransLangSelection from '../components/TransLangSelection.vue'

export default defineComponent({
  name: 'Settings',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    MainLangSelection,
    TransLangSelection
  }
})
